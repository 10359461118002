<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="8" class="mb-2">
						<div>
							<label class="font-weight-bold" for="title">Title</label>
							<p>{{models.title}}</p>
						</div>

						<div>
							<label class="font-weight-bold" for="description">Description</label>
							<div v-html="models.description"></div>
						</div>

						<div>
							<label class="font-weight-bold">Send by</label>
							<p class="text-capitalize">{{models.send_by}}</p>
						</div>
						<div>
							<label class="font-weight-bold">Send to</label>
							<p class="text-capitalize">{{models.send_to === 'all_user' ? 'All User' : `Spesific User: ${models.users_id}`}}</p>
						</div>
						<div>
							<label class="font-weight-bold">Status</label>
							<p class="text-capitalize">{{models.status}}</p>
						</div>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" block @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
	</form>
</template>
<script>
	import api from '@/utils/api.js'

	export default {
		name: 'DetailNewsletter',
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					title: '',
					description: '',
					send_by: 'email',
					send_to: 'all_user',
					users_id: [],
					status: 'publish',
				},
			}
		},
		created() {
			let _ = this
			_.getById();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.newsletter}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.title = data.title,
							_.models.description = data.description,
							_.models.send_by = data.send_by,
							_.models.send_to = data.send_to,
							_.models.users_id = data.users_id.map(user => user.name).join(", "),
							_.models.status = data.status
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			}
		}
	}
</script>